.inbox {
   padding: 20px 10px 10px 10px;
   background: rgba(255, 255, 255, 0.11);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding: 10px 5px;
    height: calc(100vh - 265px);
    overflow: auto;
    overflow-x: hidden;
    li {
      display: block;
      background-color: #393240;
      padding: 10px;
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 5px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.11);
        .textBox {
          h6 {
            color: #ffcd2f !important;
          }  
        }
      }
      .messageBox {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        overflow: hidden;
        .imgBox {
            position: relative;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }
          [class*="status"]{
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .textBox {
          color: #fff;
          width: 100%;
          h6 {
            margin-bottom: 3px;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.skeleton-loader {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  background-color: #e0e0e0;
  animation: skeleton-loading 1.2s infinite ease-in-out;
  border-radius: 4px; /* Optional: for rounded corners */
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}